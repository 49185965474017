.rightBtnIcon {
  width: 48px;
  height: 48px;
  background: linear-gradient(to bottom, #009EFF, #006AEB);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 7px;
  cursor: pointer;
  border-radius: 6px; }

.IconPhone {
  position: relative; }

.IconPhone::after {
  content: '18281829555';
  font-size: 14px;
  box-shadow: -5px 7px 13px 0px rgba(0, 109, 241, 0.15);
  line-height: 48px;
  border-radius: 4px;
  color: #006AEB;
  text-align: center;
  width: 130px;
  height: 48px;
  background: #fff;
  position: absolute;
  left: 50px;
  top: 0;
  transition: left 0s;
  z-index: -666; }

.IconPhone:hover::after {
  left: -135px;
  transition: left 0.2s ease-out; }

.IconWechat {
  position: relative; }

.IconWechat::after {
  content: '';
  text-align: center;
  width: 130px;
  height: 130px;
  background: url("https://cdn.applets.sclonsee.com/fmkjwechat.jpg?imageView2/1/w/130/h/130") no-repeat center center;
  background-color: #fff;
  box-shadow: -5px 7px 13px 0px rgba(0, 109, 241, 0.15);
  position: absolute;
  left: 50px;
  top: 0;
  transition: left 0s;
  border-radius: 4px;
  z-index: -666; }

.IconWechat:hover::after {
  left: -135px;
  transition: left 0.2s ease-out; }

.ant-message-notice-content {
  background: none;
  display: inline;
  padding: 0 0; }

.ant-message .anticon svg {
  display: none; }
