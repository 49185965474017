.Mobile_About {
  width: 100%;
  padding: 0.2rem 0.2rem 0.4rem 0.25rem;
  background: url("../../../assets/common/images/guanyuwomen-bg@3x.png") no-repeat;
  text-align: center; }
  .Mobile_About .Mobile_Title {
    font-size: 0.36rem;
    color: #fff; }
  .Mobile_About .Mobile_SubTitle {
    font-size: 0.2rem;
    color: #fff;
    margin-bottom: 0.1rem; }
  .Mobile_About .Mobile_ContentBox {
    width: 100%;
    margin-top: 0.2rem;
    overflow: hidden; }
    .Mobile_About .Mobile_ContentBox .Image {
      width: 45%; }
      .Mobile_About .Mobile_ContentBox .Image img {
        width: 100%; }
    .Mobile_About .Mobile_ContentBox .Text {
      width: 50%;
      font-size: 0.24rem;
      text-align: left;
      color: #fff;
      padding-left: 0.16rem;
      margin-bottom: 0.1rem; }
    .Mobile_About .Mobile_ContentBox button {
      width: 1.8rem;
      height: 0.6rem;
      background: #fff;
      margin-left: 0.16rem;
      border-radius: 0.06rem; }
