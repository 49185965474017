.Mobile_Service {
  width: 100%;
  padding: 0.30rem 0.64rem 0.40rem 0.64rem;
  background: #f5f9ff;
  text-align: center; }
  .Mobile_Service .Mobile_Title {
    font-size: 0.36rem;
    color: #333; }
  .Mobile_Service .Mobile_SubTitle {
    font-size: 0.20rem;
    color: #666;
    margin-bottom: 0.20rem; }
  .Mobile_Service .Mobile_Text {
    font-size: 0.20rem;
    color: #666;
    margin-top: 0.27rem; }
  .Mobile_Service .Mobile_List {
    margin-top: 0.4rem;
    overflow: hidden; }
    .Mobile_Service .Mobile_List li {
      width: 40%;
      height: 2.0rem;
      padding: 0.25rem 0.6rem 0.33rem 0.6rem;
      text-align: center;
      background: #fff;
      border-radius: 0rem 0.08rem 0rem 0.5rem;
      box-shadow: 0 0.02rem 0.1rem 0 rgba(204, 204, 204, 0.35);
      margin-bottom: 0.4rem; }
      .Mobile_Service .Mobile_List li .Mobile_Head {
        font-size: 0.3rem;
        color: #333;
        margin: 0.19rem 0 0.25rem 0; }
      .Mobile_Service .Mobile_List li .Mobile_Line {
        width: 0.4rem;
        height: 0.04rem;
        background: #DEDEDE;
        margin: 0 auto; }
    .Mobile_Service .Mobile_List li:nth-child(1) img {
      width: 0.5rem;
      height: 0.6rem; }
    .Mobile_Service .Mobile_List li:nth-child(2) img {
      width: 0.68rem;
      height: 0.6rem; }
  .Mobile_Service button {
    width: 2.4rem;
    height: 0.6rem;
    border-radius: 0.08rem;
    background: linear-gradient(90deg, #006AEB, #009EFF);
    box-shadow: 0  0.05rem 0.15rem 0 #b5d4ff;
    font-size: 0.24rem;
    color: #fff; }
