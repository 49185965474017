.AboutContainer {
  width: 100%;
  margin: 0 auto;
  padding: 60px 0 0 0;
  background: url(../../../assets/common/images/homeaboutbg.png) no-repeat; }
  .AboutContainer .AboutTitle {
    text-align: center; }
    .AboutContainer .AboutTitle p:first-child {
      font-size: 24px;
      color: #fff; }
    .AboutContainer .AboutTitle p:nth-child(2) {
      font-size: 16px;
      color: #fff;
      margin-bottom: 20px; }
  .AboutContainer .AboutContent {
    width: 100%;
    height: 400px;
    margin-top: 100px; }
    .AboutContainer .AboutContent li {
      width: 50%;
      height: 100%;
      float: left;
      position: relative; }
    .AboutContainer .AboutContent .AboutImg img {
      float: right; }
    .AboutContainer .AboutContent .AboutText p {
      max-width: 496px;
      font-size: 16px;
      margin-left: 56px;
      color: #fff; }
    .AboutContainer .AboutContent .AboutText button {
      width: 150px;
      height: 50px;
      background: #fff;
      color: #006aeb;
      border-radius: 6px;
      margin: 44px 0 0 56px;
      text-align: center;
      line-height: 50px;
      font-size: 16px; }
    .AboutContainer .AboutContent .AboutText button:hover {
      cursor: pointer; }
