.ant-modal-close {
  top: 0; }
  .ant-modal-close svg {
    width: 1.5em;
    height: 1.5em; }

.ant-modal-close-x {
  color: #fff; }

.ant-modal {
  width: 670px !important; }

.ant-modal-content {
  background: none; }

.ant-modal-content {
  box-shadow: none; }

.Bg {
  width: 600px;
  height: 640px;
  background-size: 100% 100%; }
  .Bg .Title {
    width: 100%;
    height: 165px;
    background: url("../../assets/common/images/biaodan-tou.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 40px 0;
    color: #fff; }
    .Bg .Title .Head {
      font-size: 24px;
      margin-bottom: 10px; }
    .Bg .Title .Subhead {
      font-size: 12px;
      width: 373px;
      margin: 0 auto; }
    .Bg .Title Img {
      position: relative;
      left: 220px;
      bottom: 20px; }
  .Bg .Content {
    width: 100%;
    height: 475px;
    background: #fff;
    padding-top: 50px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 40px 20px 0 20px; }
    .Bg .Content .P_Common {
      color: #333;
      font-size: 16px;
      text-align: left;
      font-weight: bold; }
    .Bg .Content .Input {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px; }
      .Bg .Content .Input .Pbox {
        display: flex;
        justify-content: space-around;
        align-items: center; }
        .Bg .Content .Input .Pbox div {
          width: 28%; }
    .Bg .Content .Tabs {
      display: flex; }
      .Bg .Content .Tabs .ant-tabs-nav-container {
        overflow: visible; }
        .Bg .Content .Tabs .ant-tabs-nav-container .ant-tabs-nav-wrap {
          overflow: visible; }
          .Bg .Content .Tabs .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
            overflow: visible; }
      .Bg .Content .Tabs .ant-tabs-bar {
        border: none; }
      .Bg .Content .Tabs .ant-tabs-tab {
        width: 100px;
        height: 36px;
        padding: 0;
        justify-content: center;
        line-height: 36px;
        font-weight: bold;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-bottom: 10px; }
      .Bg .Content .Tabs .ant-tabs-tab-active {
        border: 1px solid;
        border-radius: 4px; }
      .Bg .Content .Tabs .ant-tabs-ink-bar {
        height: 0; }
  .Bg .Btn {
    height: 120px;
    margin: 0 auto;
    position: relative;
    bottom: 45px; }
    .Bg .Btn img {
      margin: 0 auto;
      margin-right: 15px; }
  .Bg .ant-tabs-nav-animated div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

.Bg_tw {
  width: 600px;
  height: 640px;
  background-size: 100% 100%; }
  .Bg_tw .Title {
    width: 100%;
    height: 165px;
    background: url("../../assets/common/images/biaodan-tou.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding: 40px 0;
    color: #fff; }
    .Bg_tw .Title .Head {
      font-size: 24px;
      margin-bottom: 10px; }
    .Bg_tw .Title .Subhead {
      font-size: 12px;
      width: 373px;
      margin: 0 auto; }
    .Bg_tw .Title Img {
      position: relative;
      left: 220px;
      bottom: 20px; }
  .Bg_tw .Content {
    width: 100%;
    height: 475px;
    background: #fff;
    padding-top: 50px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    padding: 40px 20px 0 20px; }
    .Bg_tw .Content .P_Common {
      color: #333;
      font-size: 16px;
      text-align: left;
      padding-left: 5px;
      font-weight: bold; }
    .Bg_tw .Content .Tab {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .Bg_tw .Content .Tab .ant-tabs-tab-prev {
        display: none; }
      .Bg_tw .Content .Tab .ant-tabs-tab-next {
        display: none; }
      .Bg_tw .Content .Tab .ant-tabs-nav-container-scrolling {
        padding: 0; }
      .Bg_tw .Content .Tab .ant-tabs-tab {
        margin-right: 25px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-bottom: 10px; }
      .Bg_tw .Content .Tab .ant-tabs-nav-container {
        overflow: visible; }
        .Bg_tw .Content .Tab .ant-tabs-nav-container .ant-tabs-nav-wrap {
          overflow: visible; }
          .Bg_tw .Content .Tab .ant-tabs-nav-container .ant-tabs-nav-wrap .ant-tabs-nav-scroll {
            overflow: visible; }
      .Bg_tw .Content .Tab .ant-tabs-bar {
        border: none; }
      .Bg_tw .Content .Tab .ant-tabs-tab {
        width: 100px;
        height: 36px;
        padding: 0;
        justify-content: center;
        line-height: 36px;
        font-weight: bold; }
      .Bg_tw .Content .Tab .ant-tabs-tab-active {
        border: 1px solid;
        border-radius: 4px; }
      .Bg_tw .Content .Tab .ant-tabs-ink-bar {
        height: 0; }
    .Bg_tw .Content .TextArea {
      width: 546px;
      height: 86px;
      margin: 0 auto; }
      .Bg_tw .Content .TextArea textarea {
        width: 546px;
        height: 86px;
        resize: none;
        text-indent: 2em;
        margin: 0 auto;
        border: 1px solid #DDDDDD; }
  .Bg_tw .Btn {
    height: 120px;
    margin: 0 auto;
    position: relative;
    bottom: 45px; }
    .Bg_tw .Btn img {
      margin: 0 auto;
      margin-right: 15px; }
  .Bg_tw .ant-tabs-nav-animated div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
