.serviceContainer {
  width: 100%;
  margin: 0 auto;
  padding: 60px 0;
  background: #f5f9ff; }
  .serviceContainer .Title {
    text-align: center; }
    .serviceContainer .Title p:first-child {
      font-size: 24px; }
    .serviceContainer .Title p:nth-child(2) {
      font-size: 16px;
      color: #666666;
      margin-bottom: 20px;
      text-align: center; }
  .serviceContainer .Text {
    width: 520px;
    height: 40px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 70px;
    text-align: center; }
    .serviceContainer .Text p {
      font-size: 14px;
      color: #666666; }
  .serviceContainer .Image {
    width: 100%;
    min-height: 200px;
    display: flex;
    align-items: center; }
    .serviceContainer .Image ul {
      width: 1200px;
      height: 160px;
      margin: 0 auto; }
      .serviceContainer .Image ul li {
        width: 200px;
        height: 160px;
        border-radius: 0px 2px 0px 20px;
        background: white;
        box-shadow: 0px 1px 5px 0px rgba(204, 204, 204, 0.35);
        border-radius: 0px 2px 0px 20px;
        text-align: center;
        padding: 20px 0;
        position: relative;
        float: left;
        margin-right: 132px;
        transition: all 0.5s ease-in-out; }
        .serviceContainer .Image ul li p {
          color: #333333;
          font-size: 20px;
          margin-top: 16px;
          -webkit-touch-callout: none;
          /* iOS Safari */
          -webkit-user-select: none;
          /* Chrome/Safari/Opera */
          -khtml-user-select: none;
          /* Konqueror */
          -moz-user-select: none;
          /* Firefox */
          -ms-user-select: none;
          /* Internet Explorer/Edge */
          user-select: none;
          /* Non-prefixed version, currently

                    not supported by any browser */ }
        .serviceContainer .Image ul li span {
          position: absolute;
          bottom: 20px;
          left: 50%;
          margin-left: -10px;
          display: block;
          width: 20px;
          height: 2px;
          background: #DEDEDE; }
      .serviceContainer .Image ul li:nth-child(4) {
        margin-right: 0; }
      .serviceContainer .Image ul .app:hover {
        box-shadow: 0px 5px 15px 0px #b5d4ff;
        background-image: -webkit-linear-gradient(180deg, #009EFF, #006AEB);
        transform: translateY(-20px);
        /*transition:bottom 2s;
                transform: bottom 2s;*/ }
        .serviceContainer .Image ul .app:hover .appImg {
          display: none; }
        .serviceContainer .Image ul .app:hover .appHoverImg {
          margin: 0 auto;
          display: block; }
        .serviceContainer .Image ul .app:hover p {
          color: #fff;
          font-size: 20px;
          margin-top: 16px; }
        .serviceContainer .Image ul .app:hover span {
          background: #fff; }
      .serviceContainer .Image ul .wewat:hover {
        box-shadow: 0px 5px 15px 0px #b5d4ff;
        background-image: -webkit-linear-gradient(180deg, #009EFF, #006AEB);
        transform: translateY(-20px); }
        .serviceContainer .Image ul .wewat:hover .wewatImg {
          display: none; }
        .serviceContainer .Image ul .wewat:hover .wewatHoverImg {
          margin: 0 auto;
          display: block; }
        .serviceContainer .Image ul .wewat:hover p {
          color: #fff;
          font-size: 20px;
          margin-top: 16px; }
        .serviceContainer .Image ul .wewat:hover span {
          background: #fff; }
      .serviceContainer .Image ul .saas:hover {
        box-shadow: 0px 5px 15px 0px #b5d4ff;
        background-image: -webkit-linear-gradient(180deg, #009EFF, #006AEB);
        transform: translateY(-20px); }
        .serviceContainer .Image ul .saas:hover .saasImg {
          display: none; }
        .serviceContainer .Image ul .saas:hover .saasHoverImg {
          margin: 0 auto;
          display: block; }
        .serviceContainer .Image ul .saas:hover p {
          color: #fff;
          font-size: 20px;
          margin-top: 16px; }
        .serviceContainer .Image ul .saas:hover span {
          background: #fff; }
      .serviceContainer .Image ul .pc:hover {
        box-shadow: 0px 5px 15px 0px #b5d4ff;
        background-image: -webkit-linear-gradient(180deg, #009EFF, #006AEB);
        transform: translateY(-20px); }
        .serviceContainer .Image ul .pc:hover .pcImg {
          display: none; }
        .serviceContainer .Image ul .pc:hover .pcHoverImg {
          margin: 0 auto;
          display: block; }
        .serviceContainer .Image ul .pc:hover p {
          color: #fff;
          font-size: 20px;
          margin-top: 16px; }
        .serviceContainer .Image ul .pc:hover span {
          background: #fff; }
      .serviceContainer .Image ul .appHoverImg, .serviceContainer .Image ul .wewatHoverImg, .serviceContainer .Image ul .saasHoverImg, .serviceContainer .Image ul .pcHoverImg {
        display: none; }
  .serviceContainer .Bottom {
    width: 150px;
    height: 50px;
    margin: 0 auto;
    background: linear-gradient(90deg, #006aeb, #009eff);
    box-shadow: 0px 10px 20px 0px #b5d4ff;
    border-radius: 4px;
    margin-top: 60px;
    text-align: center; }
    .serviceContainer .Bottom p {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 50px; }
  .serviceContainer .Bottom:hover {
    cursor: pointer; }
