/*.Nav_box{
    width: 100%;
    height: 35*2px;
    position: relative;
    
    .box_Content{
        display: flex;
        display: -webkit- flex;
        justify-content: center;
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 35*2px;
        left: 0;
        top: 0;
        div{
            height: 35*2px;
        }
        .logo{
            padding: 7*2px 0;
            flex-grow: 1;
            flex:1;
            flex-direction: row-reverse;
            display: flex;
            align-items: center;
            img{
                margin-left: 180*2px;
            }

        }
        .sub_Nav{
           
           flex-grow: 1;
           flex:2;
            .subNav_box{
                width: 100%;
                height: 100%;
                div{
                    width: 45*2px;
                    height: 35*2px;
                    justify-content: flex-end;
                    flex-direction: row-reverse;
                    flex-wrap: nowrap;
                    display: inline-block;
                    text-align: center;
                    line-height: 35*2px;
                    p{
                        font-size: 8*2px;
                        color:#FEFEFEFF;
                    }
                    ul{
                        float: left;
                        position: relative;
                        width: 45*2px;
                        background: #000000FF;
                        opacity: 0 ;
                        transition: opacity 1.0s ease-in-out;
                        li{
                            line-height: 20*2px;
                        }
                        
                    }
                    
                }
                div:hover{
                    ul{
                        opacity: 1;
                        filter:alpha(opacity=1);
                    }
                    
                }
            }
        }
        .phone{
            flex-grow: 1;
            flex:1;
            padding: 20px 0;
            span{
                color: #006AEBFF;
                margin:0 0 0 5px;
            }
        }
    }
    .Mask{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 35*2px;
        background: #000000FF;
        opacity: 0.3;
    }   
}*/
.Nav_box .box_Content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  line-height: 76px;
  height: 76px;
  background: none; }
  .Nav_box .box_Content .logo {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
  .Nav_box .box_Content .sub_Nav {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row; }
    .Nav_box .box_Content .sub_Nav .navItem {
      font-size: 16px;
      justify-content: center;
      width: 90px;
      height: 76px;
      text-align: center; }
    .Nav_box .box_Content .sub_Nav .touch-action {
      text-decoration: none; }
    .Nav_box .box_Content .sub_Nav ul {
      z-index: 999999;
      width: 90px;
      top: -20px;
      display: none; }
      .Nav_box .box_Content .sub_Nav ul li {
        position: relative;
        line-height: 40px; }
        .Nav_box .box_Content .sub_Nav ul li .secondaeyText {
          color: white; }
    .Nav_box .box_Content .sub_Nav .navItem a {
      display: block;
      width: 100%;
      height: 100%; }
    .Nav_box .box_Content .sub_Nav .navItem a:focus {
      color: #1890ff;
      text-decoration: none; }
    .Nav_box .box_Content .sub_Nav .navItem ul li:hover p {
      color: #006AEB; }
    .Nav_box .box_Content .sub_Nav .navItem:hover {
      cursor: pointer; }
      .Nav_box .box_Content .sub_Nav .navItem:hover ul {
        display: block;
        cursor: pointer; }
        .Nav_box .box_Content .sub_Nav .navItem:hover ul .secondaeyMask {
          background: #000000;
          opacity: 0.3;
          position: absolute;
          left: 0;
          top: 0;
          z-index: -20;
          width: 100%;
          height: 40px; }
      .Nav_box .box_Content .sub_Nav .navItem:hover .navItemText {
        color: #006AEB; }
  .Nav_box .box_Content .phone {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Nav_box .box_Content .phone span {
      margin-left: 5px;
      color: #fff;
      font-size: 16px; }

.Mask {
  position: absolute;
  top: 0;
  width: 100%;
  height: 76px;
  opacity: 0.3;
  background: #000000; }
