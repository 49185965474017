.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden; }

.ant-carousel .slick-slide h3 {
  color: #fff; }

.AAAAA {
  display: flex !important;
  flex-direction: row !important; }

.bannerBtn:hover {
  background: #FFFFFF;
  color: #006AEB; }

.BannerText {
  max-width: 432px; }
