.newsContainer {
  width: 100%;
  margin: 0 auto;
  padding: 60px 0;
  background: #f5f9ff;
  overflow: hidden; }
  .newsContainer .Title {
    text-align: center; }
    .newsContainer .Title p:first-child {
      font-size: 24px; }
    .newsContainer .Title p:nth-child(2) {
      font-size: 16px;
      color: #666666;
      margin-bottom: 20px; }
  .newsContainer .NewsList {
    width: 1200px;
    margin: 0 auto;
    padding: 60px 0; }
    .newsContainer .NewsList .NewsListModule {
      width: 360px;
      height: 460px;
      position: relative;
      margin-right: 60px;
      float: left; }
      .newsContainer .NewsList .NewsListModule .ListImage {
        width: 360px;
        height: 240px; }
        .newsContainer .NewsList .NewsListModule .ListImage img {
          width: 360px;
          height: 240px; }
        .newsContainer .NewsList .NewsListModule .ListImage .ListHead {
          width: 84px;
          height: 84px;
          background: #006aeb;
          opacity: 0.8;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          padding-top: 16px; }
          .newsContainer .NewsList .NewsListModule .ListImage .ListHead .P_One {
            color: #FFFFFF;
            font-size: 14px; }
          .newsContainer .NewsList .NewsListModule .ListImage .ListHead .P_Two {
            color: #FFFFFF;
            font-size: 24px; }
      .newsContainer .NewsList .NewsListModule .TextList {
        width: 360px;
        height: 220px; }
        .newsContainer .NewsList .NewsListModule .TextList span {
          -webkit-touch-callout: none;
          /* iOS Safari */
          -webkit-user-select: none;
          /* Chrome/Safari/Opera */
          -khtml-user-select: none;
          /* Konqueror */
          -moz-user-select: none;
          /* Firefox */
          -ms-user-select: none;
          /* Internet Explorer/Edge */
          user-select: none;
          /* Non-prefixed version, currently

                    not supported by any browser */ }
        .newsContainer .NewsList .NewsListModule .TextList a {
          width: 360px;
          height: 25%;
          border-bottom: 1px dashed #DEDEDE;
          line-height: 25%;
          line-height: 55px; }
        .newsContainer .NewsList .NewsListModule .TextList .TextHead {
          padding-left: 35px;
          font-size: 18px;
          color: #006AEB;
          font-weight: bold;
          border-bottom: none; }
        .newsContainer .NewsList .NewsListModule .TextList .FristIcon {
          background: url("../../../assets/common/images/appkaifazixun.png") no-repeat 0px 17px;
          padding-left: 25px; }
        .newsContainer .NewsList .NewsListModule .TextList .SecondIcon {
          background: url("../../../assets/common/images/weixnkaifaxixun.png") no-repeat 0px 17px; }
        .newsContainer .NewsList .NewsListModule .TextList .ThirdIcon {
          background: url("../../../assets/common/images/dianshangkaifazixun.png") no-repeat 0px 17px; }
        .newsContainer .NewsList .NewsListModule .TextList .Timer {
          float: right;
          font-size: 14px;
          color: #666; }
        .newsContainer .NewsList .NewsListModule .TextList .SubHead {
          font-size: 16px;
          color: #333;
          padding-left: 4px; }
        .newsContainer .NewsList .NewsListModule .TextList a:hover span {
          cursor: pointer; }
    .newsContainer .NewsList li:last-child {
      margin-right: 0; }
