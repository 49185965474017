@charset "UTF-8";
/*------------------css 1px解决方案-----------------------*/
/*CSS Reset*/
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td, header, hgroup, nav, section, article, aside, footer, figure, figcaption, menu, button {
  margin: 0;
  padding: 0;
  font-family: '微软雅黑'; }

body {
  background: #f5f5f5;
  margin: 0 auto; }

body {
  font-family: Helvetica, STHeiTi, Sans-serif;
  line-height: 1.5;
  font-size: 14px;
  color: #333;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: 0; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: normal; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

fieldset, img {
  border: 0; }

li {
  list-style: none; }

input, textarea, select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearence: none;
  -ms-appearence: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  border: none;
  background: none;
  -webkit-appearance: none;
  outline: none; }

a {
  -webkit-touch-callout: none;
  text-decoration: none;
  outline: 0;
  color: #fff;
  text-decoration: none; }

a:active {
  text-decoration: none; }

em, i {
  font-style: normal; }

iframe {
  display: none; }

::-webkit-input-placeholder {
  color: #999; }

.input-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle; }

.m5 {
  margin: 5px; }

.m10 {
  margin: 10px; }

.m15 {
  margin: 15px; }

.m20 {
  margin: 20px; }

.mt5 {
  margin-top: 5px; }

.mt10 {
  margin-top: 10px; }

.mt15 {
  margin-top: 15px; }

.mt20 {
  margin-top: 20px; }

.ml5 {
  margin-left: 5px; }

.ml10 {
  margin-left: 10px; }

.ml15 {
  margin-left: 15px; }

.ml20 {
  margin-left: 20px; }

.ml30 {
  margin-left: 30px; }

.mr5 {
  margin-right: 5px; }

.mr10 {
  margin-right: 10px; }

.mr15 {
  margin-right: 15px; }

.mr20 {
  margin-right: 20px; }

.mb5 {
  margin-bottom: 5px; }

.mb10 {
  margin-bottom: 10px; }

.mb15 {
  margin-bottom: 15px; }

.mb20 {
  margin-bottom: 20px; }

.mb30 {
  margin-bottom: 30px; }

#loading {
  display: none;
  background: url(../images/loading_1.gif) rgba(12, 12, 12, 0.2) no-repeat center center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

html, body {
  color: #333; }

a:hover {
  text-decoration: none; }

.fs-12 {
  font-size: 12px; }

.fs-14 {
  font-size: 14px; }

.fs-16 {
  font-size: 16px; }

.fs-18 {
  font-size: 18px; }

.fs-20 {
  font-size: 20px; }

.fs-25 {
  font-size: 25px; }

.clear {
  clear: both; }

.blod {
  font-weight: blod; }

.normal {
  font-weight: normal; }

.cursor {
  cursor: pointer; }

.tl {
  text-align: left; }

.tc {
  text-align: center; }

.tr {
  text-align: right; }

.fl {
  float: left; }

.fr {
  float: right; }

.inline-block {
  display: inline-block; }

.w-50 {
  width: 50px; }

.w-80 {
  width: 80px; }

.w-100 {
  width: 100px; }

.w-150 {
  width: 150px; }

.w-200 {
  width: 200px; }

.w-250 {
  width: 250px; }

.w-300 {
  width: 300px; }

.w-400 {
  width: 400px; }

.w-500 {
  width: 500px; }

@font-face {
  font-family: 'iconfont';
  /* project id："133474" */
  src: url("//at.alicdn.com/t/font_6gg3y6tm884wvcxr.eot");
  src: url("//at.alicdn.com/t/font_6gg3y6tm884wvcxr.eot?#iefix") format("embedded-opentype"), url("//at.alicdn.com/t/font_6gg3y6tm884wvcxr.woff") format("woff"), url("//at.alicdn.com/t/font_6gg3y6tm884wvcxr.ttf") format("truetype"), url("//at.alicdn.com/t/font_6gg3y6tm884wvcxr.svg#iconfont") format("svg"); }

.iconfont {
  font-family: "iconfont";
  font-size: 16px;
  font-style: normal;
  vertical-align: middle; }

.data_list_empty {
  display: inline-block;
  width: 64px;
  height: 64px;
  background: url("../images/empty.png") no-repeat center center; }

.user_select {
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.nav {
  overflow: hidden;
  width: 350px;
  margin: 30px auto 0px; }
  .nav li {
    float: left;
    padding: 10px 20px; }

.btn {
  padding: 8px 15px;
  border: solid 1px #ddd; }

.input {
  height: 35px;
  border: solid 1px #ddd;
  padding-left: 10px; }

/*------------------css 1px解决方案-----------------------*/
.c-b-l:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  left: 0;
  top: 0;
  transform: scaleX(0.5);
  border-left: 1px solid #f0f0f0;
  z-index: 1; }

.c-b-r:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  right: 0;
  top: 0;
  transform: scaleX(0.5);
  border-right: 1px solid #f0f0f0;
  z-index: 1; }

.c-b-t:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  left: 0;
  top: 0;
  transform: scaleY(0.5);
  border-top: 1px solid #f0f0f0;
  z-index: 1; }

.c-b-b:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 100%;
  bottom: 0;
  left: 0;
  transform: scaleY(0.5);
  border-bottom: 1px solid #f0f0f0;
  z-index: 1; }

.c-b-c:after {
  position: absolute;
  top: -0.5px;
  left: -0.5px;
  width: 500%;
  height: 200%;
  content: '';
  border: 1px solid #f0f0f0;
  border-radius: 0;
  transform: scale(0.5);
  transform-origin: top left;
  z-index: -1;
  display: block; }
