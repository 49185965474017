.CaseContainer {
  width: 100%;
  margin: 0 auto;
  padding-top: 60px;
  background: white; }
  .CaseContainer .CaseTitle {
    text-align: center; }
    .CaseContainer .CaseTitle p:first-child {
      font-size: 24px; }
    .CaseContainer .CaseTitle p:nth-child(2) {
      font-size: 16px;
      color: #666666;
      margin-bottom: 20px; }
  .CaseContainer .ant-tabs-ink-bar {
    width: 60px !important;
    margin-left: 15px; }
  .CaseContainer .CaseTable {
    width: 800px;
    margin: 0 auto;
    padding-top: 40px; }
    .CaseContainer .CaseTable li {
      width: 25%;
      text-align: center;
      float: left;
      position: relative;
      font-weight: bold;
      color: #333333;
      font-size: 16px; }
      .CaseContainer .CaseTable li span {
        display: block;
        width: 48px;
        height: 4px;
        background: #006AEB;
        position: absolute;
        left: 50%;
        margin-left: -24px;
        bottom: -10px; }
    .CaseContainer .CaseTable li:hover {
      cursor: pointer;
      color: #006aeb; }
  .CaseContainer .ant-tabs-nav-scroll {
    white-space: normal !important; }
  .CaseContainer .ant-tabs {
    overflow: visible; }
  .CaseContainer .CaseContent {
    width: 100%;
    height: 480px;
    margin-top: 100px;
    background: url("../../../assets/common/images/kaifaanli-bg.png") no-repeat;
    background-size: 100% 100%;
    position: relative; }
    .CaseContainer .CaseContent .Lift {
      width: 50%;
      height: 100%;
      float: left;
      display: flex;
      justify-content: center;
      align-items: center; }
      .CaseContainer .CaseContent .Lift .ImgShow {
        float: left;
        box-shadow: 0 2px 15px 0 #cfcfd1; }
      .CaseContainer .CaseContent .Lift .ListOne {
        width: 166px;
        height: 362px;
        margin-right: 50px; }
        .CaseContainer .CaseContent .Lift .ListOne .ImgShow {
          width: 166px;
          height: 363px; }
          .CaseContainer .CaseContent .Lift .ListOne .ImgShow img {
            width: 100%;
            height: 100%; }
      .CaseContainer .CaseContent .Lift .ListOne .ImgShow {
        animation-name: Chage;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 1.5s; }

@keyframes Chage {
  from {
    width: 166px;
    height: 363px;
    opacity: 1;
    box-shadow: 0 4px 20px 0 #cfcfcf; }
  to {
    width: 162.68px;
    height: 355.74px;
    opacity: 0.7;
    box-shadow: 0 0 0 0; } }
      .CaseContainer .CaseContent .Lift .ListOne .ImgShow:hover {
        animation: none;
        transform: scale(1);
        box-shadow: 0px 1px 15px 4px #b9b9b9; }
      .CaseContainer .CaseContent .Lift .ListTwo .ImgShow {
        animation: Chages;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 1.5s; }

@keyframes Chages {
  from {
    width: 208px;
    height: 452px;
    opacity: 1;
    box-shadow: 0 4px 20px 0 #cfcfd1; }
  to {
    width: 203.84px;
    height: 442.96px;
    opacity: 0.7;
    box-shadow: 0 0 0 0; } }
      .CaseContainer .CaseContent .Lift .ListTwo .ImgShow:hover {
        animation: none;
        transform: scale(1);
        box-shadow: 0px 0 15px 4px #b9b9b9; }
      .CaseContainer .CaseContent .Lift .ListTwo {
        width: 208px;
        height: 452px; }
        .CaseContainer .CaseContent .Lift .ListTwo .ImgShow {
          width: 208px;
          height: 452px; }
          .CaseContainer .CaseContent .Lift .ListTwo .ImgShow img {
            width: 100%;
            height: 100%; }
    .CaseContainer .CaseContent .Right {
      width: 50%;
      height: 100%;
      float: left; }
      .CaseContainer .CaseContent .Right .TextBox {
        width: 420px;
        height: 100%;
        text-align: center;
        margin-top: 95px; }
        .CaseContainer .CaseContent .Right .TextBox p {
          color: #333333; }
        .CaseContainer .CaseContent .Right .TextBox .TextHead {
          font-size: 18px;
          font-weight: bold; }
        .CaseContainer .CaseContent .Right .TextBox .TextCont {
          font-size: 14px;
          text-align: left;
          margin-top: 23px; }
        .CaseContainer .CaseContent .Right .TextBox a {
          float: left;
          margin-top: 30px;
          text-align: center;
          color: #666;
          font-size: 16px; }
        .CaseContainer .CaseContent .Right .TextBox a:hover {
          cursor: pointer;
          color: #006AEB;
          border-bottom: 1px solid #006AEB; }
    .CaseContainer .CaseContent .Phone {
      width: 286px;
      height: 576px;
      background: url("../../../assets/common/images/shouji.png") no-repeat;
      background-size: 100% 100%;
      position: relative;
      left: 0;
      top: -10%; }
      .CaseContainer .CaseContent .Phone .ant-carousel {
        position: absolute;
        left: 31px;
        top: 23px;
        width: 223px; }
      .CaseContainer .CaseContent .Phone img {
        width: 100%;
        height: 515px; }
      .CaseContainer .CaseContent .Phone .slick-list {
        border-radius: 26px; }
      .CaseContainer .CaseContent .Phone .slick-dots li button {
        background: red;
        width: 16px;
        height: 4px;
        border-radius: 3px;
        margin-top: 2px; }
  .CaseContainer .CaseWeb {
    width: 100%;
    height: 480px;
    display: flex;
    justify-content: space-between; }
    .CaseContainer .CaseWeb .Lift {
      flex: 1; }
      .CaseContainer .CaseWeb .Lift .ListOne {
        width: 331.5px;
        height: 216px;
        margin-right: 35px; }
        .CaseContainer .CaseWeb .Lift .ListOne img {
          width: 100%;
          height: 100%; }
    .CaseContainer .CaseWeb .Right {
      flex: 1;
      justify-content: space-evenly; }
      .CaseContainer .CaseWeb .Right .Phone {
        width: 558px;
        height: 446px;
        background: url("../../../assets/common/images/homecasediannao.png") no-repeat;
        top: 5%; }
        .CaseContainer .CaseWeb .Right .Phone img {
          height: 100%; }
      .CaseContainer .CaseWeb .Right .slick-list {
        border-radius: 0; }
      .CaseContainer .CaseWeb .Right .ant-carousel {
        width: 514px;
        height: 288px;
        left: 23px; }
  .CaseContainer .CaseFooter {
    width: 100%;
    height: 140px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center; }
    .CaseContainer .CaseFooter a {
      width: 150px;
      height: 50px;
      margin: 0 auto;
      font-size: 16px;
      line-height: 50px;
      background: linear-gradient(90deg, #006aeb, #009eff);
      box-shadow: 0px 10px 20px 0px #b5d4ff;
      color: #fff;
      border-radius: 6px;
      overflow: hidden; }
    .CaseContainer .CaseFooter a:hover {
      cursor: pointer; }
  .CaseContainer .ant-tabs-bar {
    border-bottom: none;
    display: flex;
    justify-content: center; }
