.Mobile_CaseContainer {
  width: 100%;
  padding: 0.4rem 0.4rem;
  text-align: center; }
  .Mobile_CaseContainer .Mobile_Title {
    font-size: 0.36rem;
    color: #333; }
  .Mobile_CaseContainer .Mobile_SubTitle {
    font-size: 0.2rem;
    color: #666;
    margin-bottom: 0.2rem; }
  .Mobile_CaseContainer .Mobile_Table {
    width: 100%;
    text-align: center;
    margin-top: 0.2rem; }
    .Mobile_CaseContainer .Mobile_Table td {
      width: 25%;
      font-size: 0.24rem;
      font-weight: bold; }
      .Mobile_CaseContainer .Mobile_Table td a {
        color: #333; }
      .Mobile_CaseContainer .Mobile_Table td p {
        width: 0.48rem;
        height: 0.04rem;
        margin: 0 auto;
        background: #006AEB; }
      .Mobile_CaseContainer .Mobile_Table td td:hover {
        cursor: pointer; }
    .Mobile_CaseContainer .Mobile_Table td:first-child a {
      color: #006AEB; }
  .Mobile_CaseContainer .Mobile_CaseImg {
    width: 100%;
    overflow: hidden;
    margin-top: 0.4rem;
    margin-bottom: 0.1rem; }
    .Mobile_CaseContainer .Mobile_CaseImg li {
      width: 2.92rem;
      height: 2.92rem;
      overflow: hidden;
      margin-bottom: 30px; }
      .Mobile_CaseContainer .Mobile_CaseImg li img {
        width: 100%;
        height: 100%; }
  .Mobile_CaseContainer button {
    width: 1.8rem;
    height: 0.6rem;
    background: linear-gradient(90deg, #006AEB, #009EFF);
    text-align: center;
    line-height: 0.6rem; }
