.footer {
  clear: both;
  background: #30343e; }

.content {
  display: flex; }

.scanCard {
  background: url("../../assets/common/images/erweimakuang.png") no-repeat; }

.LinkA:hover {
  color: #fff !important; }

.CardBox {
  position: relative;
  overflow: hidden; }
  .CardBox .CardLine {
    position: absolute;
    top: -3px;
    left: 0;
    width: 260px;
    height: 3px;
    background: #fff;
    opacity: 0.3;
    animation-name: WechatChange;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 1.5s; }

@keyframes WechatChange {
  from {
    top: -3px; }
  to {
    top: 137px; } }

.CardBox:hover {
  cursor: pointer; }
  .CardBox:hover .CardLine {
    display: none; }
