.PartnersContainer {
  width: 100%;
  padding: 60px 0 40px 0;
  background: url("../../../assets/common/images/hezuohuoban-bg.png") no-repeat;
  position: relative; }
  .PartnersContainer .PartnersTitle {
    text-align: center; }
    .PartnersContainer .PartnersTitle p:first-child {
      font-size: 24px; }
    .PartnersContainer .PartnersTitle p:nth-child(2) {
      font-size: 16px;
      color: #666666;
      margin-bottom: 20px; }
  .PartnersContainer .PartnersContent {
    width: 1200px;
    overflow: hidden;
    margin: 0 auto;
    margin-top: 60px;
    z-index: 5;
    position: sticky; }
    .PartnersContainer .PartnersContent li {
      float: left;
      border: 1px solid #DEDEDE;
      width: 230px;
      height: 134px;
      text-align: center;
      line-height: 134px;
      margin: 0 10px 20px 0;
      background: #fff;
      z-index: 3; }
      .PartnersContainer .PartnersContent li img {
        transition: all 1s; }
    .PartnersContainer .PartnersContent li:hover {
      cursor: pointer;
      box-shadow: 0px 5px 15px 0px rgba(32, 33, 34, 0.2); }
      .PartnersContainer .PartnersContent li:hover img {
        transform: scale(1.1); }
  .PartnersContainer .Square {
    z-index: 2;
    position: absolute;
    right: 203px;
    bottom: -60px; }
