.Mobile_News {
  width: 100%;
  padding: 0.3rem 0.25rem 0.4rem 0.25rem;
  text-align: center; }
  .Mobile_News .Mobile_Title {
    font-size: 0.36rem;
    color: #333; }
  .Mobile_News .Mobile_SubTitle {
    font-size: 0.2rem;
    color: #666;
    margin-bottom: 0.2rem; }
  .Mobile_News .Mobile_NewsContent {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin: 0.4rem 0; }
    .Mobile_News .Mobile_NewsContent img {
      width: 2.4rem;
      height: 1.6rem; }
    .Mobile_News .Mobile_NewsContent .Sign {
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      background: #006AEB;
      padding: 0 0.1rem; }
      .Mobile_News .Mobile_NewsContent .Sign .P_Big {
        font-size: 0.28rem;
        color: #fff; }
      .Mobile_News .Mobile_NewsContent .Sign .P_Small {
        font-size: 0.02rem;
        color: #fff; }
    .Mobile_News .Mobile_NewsContent .P_Head {
      padding-left: 0.1rem;
      font-size: 0.28rem;
      width: 64%;
      text-align: left;
      color: #333; }
    .Mobile_News .Mobile_NewsContent .P_Cont {
      padding-left: 0.1rem;
      width: 64%;
      font-size: 0.24rem;
      color: #999;
      text-align: left; }
  .Mobile_News button {
    width: 1.8rem;
    height: 0.6rem;
    background: #006AEB;
    margin: 0 auto; }
