body {
  overflow-x: hidden; }

.About_Middle_one {
  width: 100%;
  background: url(../../assets/common/images/fanmenkeji-bg@2x.png) no-repeat;
  padding-bottom: 40px; }
  .About_Middle_one .ContainerBox {
    width: 1200px;
    height: 640px;
    margin: 0 auto;
    position: relative; }
    .About_Middle_one .ContainerBox .NavTitle {
      width: 1200px;
      height: 160px;
      background: #fff;
      box-shadow: 0px 3px 8px 0px rgba(32, 33, 34, 0.15);
      position: absolute;
      top: -80px;
      z-index: 200; }
      .About_Middle_one .ContainerBox .NavTitle li {
        width: 25%;
        height: 100%;
        float: left;
        text-align: center; }
        .About_Middle_one .ContainerBox .NavTitle li .Head {
          color: #006AEB;
          font-size: 30px;
          margin: 25px 0 10px 0; }
        .About_Middle_one .ContainerBox .NavTitle li .Line {
          width: 24px;
          height: 2px;
          background: #ddd;
          margin: 0 auto 20px auto; }
        .About_Middle_one .ContainerBox .NavTitle li .Cont {
          font-size: 14px;
          color: #333; }
    .About_Middle_one .ContainerBox .TextTitle {
      font-size: 24px;
      color: #333;
      padding-top: 190px;
      text-align: center;
      font-weight: bold; }
    .About_Middle_one .ContainerBox .Text {
      font-size: 16px;
      color: #666;
      line-height: 2;
      text-align: justify;
      width: 508px;
      margin-top: 40px;
      margin-bottom: 20px;
      text-indent: 2em; }
    .About_Middle_one .ContainerBox .Text_Two {
      width: 508px;
      color: #333;
      font-size: 14px;
      text-indent: 2em; }
    .About_Middle_one .ContainerBox img {
      position: absolute;
      right: 40px;
      top: 160px; }

.About_Middle_two {
  width: 100%;
  height: 670px;
  background: white; }
  .About_Middle_two .ContainerBox {
    width: 1200px;
    height: 100%;
    margin: 0 auto; }
    .About_Middle_two .ContainerBox .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center;
      font-weight: bold;
      padding-top: 60px; }
    .About_Middle_two .ContainerBox .Content {
      width: 1200px;
      height: 430px;
      margin: 60px auto 0 auto;
      display: flex;
      justify-content: space-around; }
      .About_Middle_two .ContainerBox .Content li {
        width: 280px;
        height: 360px;
        position: relative; }
        .About_Middle_two .ContainerBox .Content li .Show {
          width: 280px;
          height: 160px;
          position: absolute;
          bottom: 0;
          left: 0;
          background: #2385D8;
          padding: 26px 40px;
          display: none;
          color: #FFFEFEFE; }
        .About_Middle_two .ContainerBox .Content li img {
          width: 280px;
          height: 360px; }
        .About_Middle_two .ContainerBox .Content li .P_Con {
          font-size: 16px;
          color: #333;
          text-align: center; }
        .About_Middle_two .ContainerBox .Content li .P_One {
          margin: 20px 0 10px 0; }
      .About_Middle_two .ContainerBox .Content li:last-child {
        margin-right: 0; }
      .About_Middle_two .ContainerBox .Content li:hover {
        cursor: pointer; }
        .About_Middle_two .ContainerBox .Content li:hover .Show {
          display: block; }

.About_Middle_three {
  width: 100%;
  background: url("../../assets/common/images/licheng-bg@2x.png") no-repeat 0 -215px;
  padding-bottom: 60px; }
  .About_Middle_three .ContainerBox {
    width: 1200px;
    height: 100%;
    margin: 0 auto; }
    .About_Middle_three .ContainerBox .ant-timeline-item-tail {
      border: 1px solid #333; }
    .About_Middle_three .ContainerBox .Title {
      color: #333;
      font-size: 24px;
      margin-bottom: 80px;
      text-align: center;
      font-weight: bold;
      padding-top: 60px; }
    .About_Middle_three .ContainerBox .Time {
      margin-bottom: 20px; }
    .About_Middle_three .ContainerBox .TextHead {
      font-size: 16px;
      color: #333; }
    .About_Middle_three .ContainerBox .TextCont {
      font-size: 14px;
      color: #666;
      margin-bottom: 20px; }

.About_Middle_four {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-top: 60px; }
  .About_Middle_four .Title {
    color: #333;
    font-size: 24px;
    margin-bottom: 15px; }
    .About_Middle_four .Title span {
      color: #006AEB; }
  .About_Middle_four .SubTitle {
    color: #666;
    font-size: 16px;
    width: 520px;
    margin: 0 auto; }
  .About_Middle_four .Buttom {
    width: 100%;
    height: 350px;
    margin-top: 60px;
    margin-bottom: 60px; }
    .About_Middle_four .Buttom a {
      display: block;
      width: 360px;
      height: 360px;
      float: left;
      background: white;
      box-shadow: 0px 3px 5px 0px rgba(49, 49, 49, 0.15);
      margin-right: 30px;
      position: relative; }
      .About_Middle_four .Buttom a .ImgBox {
        width: 360px;
        height: 240px; }
        .About_Middle_four .Buttom a .ImgBox .Img {
          width: 360px;
          height: 240px; }
      .About_Middle_four .Buttom a .P_Con {
        margin: 12px 20px 0 10px;
        text-align: left; }
      .About_Middle_four .Buttom a .P_Head {
        font-size: 16px; }
      .About_Middle_four .Buttom a .P_Text {
        font-size: 14px;
        color: #999999;
        padding-bottom: 10px; }
      .About_Middle_four .Buttom a .Mask_DeatilsBtm {
        position: absolute;
        top: 0;
        left: 0;
        width: 360px;
        height: 240px;
        background: #006aeb;
        opacity: 0.8;
        display: none; }
      .About_Middle_four .Buttom a .Triangle {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 18px solid white;
        position: absolute;
        bottom: 110px;
        right: 60px;
        display: none; }
      .About_Middle_four .Buttom a .MaskText {
        width: 360px;
        height: 240px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        padding: 88px 0;
        display: none; }
        .About_Middle_four .Buttom a .MaskText p:nth-child(1) {
          font-size: 14px;
          color: white; }
        .About_Middle_four .Buttom a .MaskText p:nth-child(2) {
          font-size: 24px;
          color: white; }
    .About_Middle_four .Buttom li:nth-child(3) {
      margin-right: 0; }
    .About_Middle_four .Buttom a:hover {
      cursor: pointer; }
      .About_Middle_four .Buttom a:hover .Triangle {
        display: block; }
      .About_Middle_four .Buttom a:hover .MaskText, .About_Middle_four .Buttom a:hover .Mask_DeatilsBtm {
        display: block; }
      .About_Middle_four .Buttom a:hover .P_Head {
        color: #006AEB; }

.About_Middle_five {
  width: 100%;
  height: 626px;
  background: #f5f9ff; }
  .About_Middle_five .About_Container {
    width: 1200px;
    height: 100%;
    margin: 0 auto; }
    .About_Middle_five .About_Container .Title {
      text-align: center;
      font-size: 24px;
      color: #333;
      padding-top: 60px; }
    .About_Middle_five .About_Container .ContentMapBox {
      margin-top: 40px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .About_Middle_five .About_Container .ContentMapBox .Map {
        width: 800px;
        height: 400px; }
      .About_Middle_five .About_Container .ContentMapBox .MapText {
        margin-left: 40px;
        width: 360px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 100px 0; }
        .About_Middle_five .About_Container .ContentMapBox .MapText p {
          width: 100%; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .Line {
          margin-bottom: 40px; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .MapTextItem {
          padding-left: 30px;
          font-size: 14px;
          margin-bottom: 20px; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .MapTextItem_Phone {
          background: url("../../assets/common/images/dianhua@2x.png") no-repeat; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .MapTextItem_Email {
          background: url("../../assets//common/images/youxiang@2x.png") no-repeat; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .MapTextItem_WorkTime {
          background: url("../../assets//common/images/shijian@2x.png") no-repeat; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .MapTextItem_site {
          background: url("../../assets/common/images/weizhi@2x.png") no-repeat 2px 0; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .MapTitle {
          font-size: 20px;
          color: #333; }
        .About_Middle_five .About_Container .ContentMapBox .MapText .Line {
          width: 40px;
          height: 4px;
          background: #006AEB; }
