body {
  overflow-x: hidden; }

.home {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 50px; }
  .home .but {
    padding: 8px 10px;
    border: solid 1px #ccc;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 0 0 5px #ccc; }

.mt20 {
  margin-top: 20px; }

.redux {
  font-size: 25px;
  color: red; }

.button_add {
  font-size: 18px;
  border: solid 1px #000;
  color: #000;
  margin-left: 20px;
  cursor: pointer; }

.home-button {
  padding: 5px 10px;
  border: solid 1px #6f8fb7;
  margin-left: 10px;
  cursor: pointer;
  color: #fff;
  background: #6f8fb7;
  border-radius: 3px; }

.rightBtnIcon {
  width: 48px;
  height: 48px;
  background: linear-gradient(to bottom, #009EFF, #006AEB);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 7px;
  cursor: pointer; }

.Modal .ant-modal-close-icon {
  color: white; }

.Modal .ant-modal-content {
  background: none;
  box-shadow: none; }

.ant-tabs-nav-scroll {
  white-space: pre-wrap !important; }

.ant-message-notice-content {
  background: none;
  display: inline; }

.ant-message .anticon svg {
  display: none; }
